import styled from "styled-components"

type Props = {
  level: number
  variable?: string
  color: string
  weight: "500" | "700"
  capitalize?: boolean
  uppercase?: boolean
}

const Paragraph = styled.p<Props>`
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-size: ${props =>
    props.level === 1
      ? "14px"
      : props.level === 2
      ? "11px"
      : props.level === 3
      ? "14px"
      : "14px"};
  line-height: 130%;
  font-weight: ${props => props.weight};
  letter-spacing: 0.03em;
  color: ${props =>
    props.theme.variables[props?.variable ?? "secondary"][props?.color]
      ? props.theme.variables[props?.variable ?? "secondary"][props?.color]
      : props.theme.variables.primary.black};
  text-transform: ${props =>
    props?.capitalize ? "capitalize" : props?.uppercase ? "uppercase" : "none"};
  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    font-size: ${props =>
      props.level === 1
        ? "16px"
        : props.level === 2
        ? "12px"
        : props.level === 3
        ? "14px"
        : "14px"};
    line-height: 130%;
  }

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
    font-size: ${props =>
      props.level === 1
        ? "18px"
        : props.level === 2
        ? "16px"
        : props.level === 3
        ? "14px"
        : "14px"};
    line-height: 130%;
  }
`

export default Paragraph
